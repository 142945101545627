import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'
import { legacyBreakpoints } from '../utils/media'
import MarkdownContent from './MarkdownContent'
import Wrapper from './Wrapper'

const MemberContainer = styled.div`
  text-align: center;
  position: relative;
  background-color: white;
  border: 1px solid var(--color-light-outline);
  border-radius: var(--border-radius);
  margin: 50px 10px;
  padding: 0 20px 20px;
  width: calc(100% / 3 - 62px);

  @media screen and (max-width: ${legacyBreakpoints.tablet}) {
    width: calc(50% - 62px);
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    width: 100%;
  }
`

const TeamMemberAvatar = styled(GatsbyImage)`
  top: -50px;
  border-radius: 50%;
  border: 3px solid white;
  margin: 0 auto;
`

const additionalStyles = css`
  padding: unset;
`

const TeamMember = ({ name, position, bio, image }) => (
  <MemberContainer>
    <TeamMemberAvatar
      image={image}
      alt={name}
    />
    <h3>{name}</h3>
    <h4>{position}</h4>
    <MarkdownContent
      el={bio?.childMarkdownRemark.htmlAst}
      additionalStyles={additionalStyles}
    />
  </MemberContainer>
)

const TeamMembersWrapper = styled(Wrapper)`
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const PageSectionTeamMembers = ({ members }) => (
  <TeamMembersWrapper>
    {members.map((member) => (
      <TeamMember
        key={member.id}
        name={member.name}
        position={member.title}
        bio={member.bio}
        image={getImage(member.photo.localFile)}
      />
    ))}
  </TeamMembersWrapper>
)

export default PageSectionTeamMembers
